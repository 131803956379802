<template>
  <div class="case">
    <banner img="../assets/img/bgtop.jpg" title="" />
    <div class="case-section" v-loading="loading">
      <div class="case-section-content">
        <div class="case-section-content-list" v-for="(cas,index) in caseList" :key="index">
          <router-link class="text-decoration" :to="{ name: 'casedetails', params: { id: cas.id }}">
            <img v-lazy="imgserver+cas.img" />
          </router-link>
          <div class="content-list-abstract" :class="{'abstract-active' : index%2!=1}">
            <p class="abstract-title">{{cas.title}}</p>
            <div class="abstract-content" v-html="cas.content"></div>
            <div class="more">
              <router-link
                class="text-decoration"
                :to="{ name: 'casedetails', params: { id: cas.id }}"
              >
                <span>more</span>
                <img src="../assets/img/sanjiao.png" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div style="text-align: center" >
        <pagination
            v-show="page.total > 0"
            :total="page.total"
            :page.sync="page.pageNum"
            :limit.sync="page.pageSize"
            @pagination="loadData"/>
      </div>
    </div>
  </div>
</template>
<script>
import { getCasesAll } from "@/services";
import Banner from "../components/Banner";
export default {
  components: {
    Banner
  },
  data() {
    return {
      loading: true,
      caseList: [],
      page: {
        pageNum:1,
        pageSize:5,
        total: 0,
      },
    };
  },
  mounted() {
    this.loadData();

  },
  methods: {
    loadData(){
      getCasesAll(this.page).then(response => {
        this.caseList = response.rows;
        this.loading = false;
        this.page.total = response.total;
      })
    }
  }
};
</script>

 <style lang="scss">
.case {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: #14679f;

  &-section {
    width: 100%;
    &-content {
      width: 1240px;
      margin: 0 auto;
      background-color: #fff;

      &-list {
        width: 100%;
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid pink;
        //border-bottom: 1px solid #15669e;
        img {
          max-width: 612px;
          max-height: 314px;
        }
        .content-list-abstract {
          width: 290px;
          height: 350px;
          padding: 0 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .abstract-title {
            line-height: 30px;
            font-size: 22px;
            color: #e13834;
          }
          .abstract-content {
            height: 150px;
            color: #484848;
            font-size: 15px;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 7;
            -webkit-box-orient: vertical;
            white-space: normal !important;
            word-wrap: break-word;
            //border: 1px solid pink;
            p img{
              display: none;
            }
          }
          .more {
            display: flex;
            justify-content: flex-start;
            .text-decoration {
              text-decoration: none;

              span {
                color: #000;
              }
              img {
                width: 12px;
                height: 12px;
              }
            }
          }
        }
        .abstract-active {
          order: -1;
        }
      }
    }
  }
}
.mobile {
  .case-section-content {
    width: auto;
    .case-section-content-list {
      display: block;
      height: auto;
      border-bottom: 1px solid #15669e;
      img {
        width: 100%;
        height: auto;
      }
      .content-list-abstract {
        width: auto;
        height: auto;
        .abstract-title {
          margin-top: 10px;
        }
        .abstract-content {
          height: 150px;
          margin: 10px 0;
          p img{
            display: none;
          }
        }
        .more {
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>